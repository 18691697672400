import './Todo.sass';
import React, { Component } from "react";

const todoItems = [
  {
    id: 1,
    title: "Go to Market",
    description: "Buy ingredients to prepare dinner",
    completed: true,
  },
  {
    id: 2,
    title: "Study",
    description: "Read Algebra and History textbook for the upcoming test",
    completed: false,
  },
  {
    id: 3,
    title: "Sammy's books",
    description: "Go to library to return Sammy's books",
    completed: true,
  },
  {
    id: 4,
    title: "Article",
    description: "Write article on how to use Django with React",
    completed: false,
  },
];

class TodoApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewCompleted: false,
      todoList: todoItems,
    }
  };

  displayCompleted = (status) => {
    if (status) {
      return this.setState({ viewCompleted: true });
    }

    return this.setState({ viewCompleted: false });
  }

  renderTabList = () => {
    return  (
      <div class="flex space-x-4 pb-2 mb-5 border-b-2 border-b-orange-950">
        <span
          className={`btn ${this.state.viewCompleted ? "nav-link active" : "nav-link"}`}
          onClick={() => this.displayCompleted(true)}>
          Complete
        </span>
        <span
          className={`btn ${this.state.viewCompleted ? "nav-link" : "nav-link active"}`}
          onClick={() => this.displayCompleted(false)}>
          Incomplete
        </span>
      </div>
    );
  };

  renderItems = () => {
    const { viewCompleted } = this.state;
    const newItems = this.state.todoList.filter(
      (item) => item.completed === viewCompleted
    );

    return newItems.map((item) => (
      <li
        key={item.id}
        className='list-group-item flex justify-between items-center'
      >
        <span
          className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""}`}
          title={item.description}
        >
          {item.title}
        </span>
        <span>
          <button className='btn btn-primary me-2'>Edit</button>
          <button className='btn btn-seconary'>Delete</button>
        </span>
      </li>
    ));

  };

  render () {
    return (
      <div className='w-full h-lvh p-7 bg-slate-800'>
        <h1 className='text-white'>Todo App</h1>
        <main className="container mx-auto rounded-md bg-slate-100">
          <div className="p-3 rounded-md">
            <div className="mb-4">
              <button
                className="btn btn-primary"
              >
                Add task
              </button>
            </div>
            {this.renderTabList()}
            <ul className="list-group list-group-flush border-top-0">
              {this.renderItems()}
            </ul>
          </div>
        </main>
      </div>
    );
  };

};

export default TodoApp;
